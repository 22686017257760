<template>
  <div id="roles-form-edit">
    <b-modal
      centered
      hide-footer
      ref="modalEdit"
      title="Estas editando un rol ya creado">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Rol a editar" label-for="name">
              <b-form-input id="name"
                v-model="editForm.name"
                name="login-email"
                placeholder="Escribe el nombre aqui"
                :state="nameErrors.length > 0 ? false:null"
                @input="$v.editForm.name.$touch()"/>
              <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
            <b-form-group>
              <div>
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()">
                  <b-button block
                    ref="button"
                    :disabled="overlayForm"
                    variant="primary"
                    @click.prevent="_updateRole()">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Guardar
                    </span>
                  </b-button>
                </b-overlay>
              </div>
            </b-form-group>
          </div>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    name: 'RolesFormEdit',
    data(){
      return {
        overlayForm: false,
        editForm: {
          id: 1,
          name: '',
          permissions: [],
        },
        permissions: [
          { id: 1, name: 'Leer' },
          { id: 2, name: 'Editar' },
          { id: 3, name: 'Eliminar' },
          { id: 4, name: 'Descargar' },
        ]
      }
    },
    validations: {
      editForm: {
        name: {
          required,
        },
      },
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.editForm.name.$dirty) return errors
        !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('roles',['updateRoles']),
      async _updateRole () {
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.overlayForm = true
          await this.updateRoles(this.editForm)
          .then(res => {
            this._hideModal();
            this.editForm = {name: '',permissions: []}
            this.$v.editForm.$reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Rol modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el rol',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _showModal(role) {
        this.editForm.id = role.id
        this.editForm.name = role.name
        this.$refs['modalEdit'].show()
      },
      _hideModal() {
        this.$refs['modalEdit'].hide()
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>
