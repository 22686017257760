<template>
  <div id="roles">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <roles-form-create></roles-form-create>
          <roles-list :roles="roles"></roles-list>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex';
  import RolesList from './components/RolesList'
  import RolesFormCreate from './components/RolesFormCreate'

  export default{
    name: 'RolesView',
    components: {RolesList,RolesFormCreate},
    data() {
      return {
      }
    },
    beforeMount() {
      this._fetchRoles()
    },
    computed: {
      ...mapState('roles',['roles']),
    },
    methods: {
      ...mapActions('roles',['fetchRoles']),
      async _fetchRoles () {
        await this.fetchRoles()
      },
    }
  }
</script>