<template>
  <div id="roles-list" class="mt-2">
    <b-table
      hover
      striped
      show-empty
      responsive="sm"
      class="position-relative mb-0"
      :items="roles"
      :fields="tableColumns"
      primary-key="id"
      empty-text="Datos no Disponibles">
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret
          :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon size="16"
              icon="MoreVerticalIcon"
              class="align-middle text-body"/>
          </template>
          <b-dropdown-item @click.prevent="_auditRole(data.item)" v-if="permissions_visible.assign_role">
            <feather-icon icon="BookmarkIcon"/>
            <span class="align-middle ml-50">Auditoria</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="_editRole(data.item)" v-if="permissions_visible.edit_role">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="_deleteRole(data.item)" v-if="permissions_visible.delete_role">
            <feather-icon icon="TrashIcon"  />
            <span class="align-middle ml-50">Eliminar</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <roles-form-edit ref="modalRoleEdit"></roles-form-edit>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import RolesFormEdit from './RolesFormEdit';
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'RolesList',
    props: ['roles'],
    components:{RolesFormEdit},
    data () {
      return {
        permissions_visible:{
          create_role:false,
          show_roles:false,
          edit_role:false,
          assign_role:false,
          delete_role:false,
        },
        selectRole: [],
        tableColumns: [
          { key: 'id', sortable: false, label: 'ID'},
          { key: 'name', sortable: false, label: 'Nombre' },
          { key: 'actions', label: 'Acciones' },
        ]
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
    },
    methods: {
      ...mapActions('roles',['deleteRoles']),
      _auditRole(role) {
        this.$router.push({ name: 'roles-audits', params: { id: role.id, audits: role } })
      },
      _editRole(role) {
        this.$refs.modalRoleEdit._showModal(role)
      },
      _deleteRole(role) {
        this.$swal({
          title: '¿Estás seguro que deseas eliminar este rol?',
          text: 'La siguiente acción eliminará el rol seleccionado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this._confirmDelete(role);
          }
        })
      },
      async _confirmDelete(role) {
        await this.deleteRoles(role)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rol eliminado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al eliminar el rol',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
    }
  }
</script>
